exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-submission-success-js": () => import("./../../../src/pages/submission-success.js" /* webpackChunkName: "component---src-pages-submission-success-js" */),
  "component---src-templates-page-contact-us-js": () => import("./../../../src/templates/PageContactUs.js" /* webpackChunkName: "component---src-templates-page-contact-us-js" */),
  "component---src-templates-page-location-and-hours-js": () => import("./../../../src/templates/PageLocationAndHours.js" /* webpackChunkName: "component---src-templates-page-location-and-hours-js" */),
  "component---src-templates-page-our-community-js": () => import("./../../../src/templates/PageOurCommunity.js" /* webpackChunkName: "component---src-templates-page-our-community-js" */),
  "component---src-templates-page-request-a-dentist-appointment-js": () => import("./../../../src/templates/PageRequestADentistAppointment.js" /* webpackChunkName: "component---src-templates-page-request-a-dentist-appointment-js" */),
  "component---src-templates-page-sanity-content-block-js": () => import("./../../../src/templates/PageSanityContentBlock.js" /* webpackChunkName: "component---src-templates-page-sanity-content-block-js" */),
  "component---src-templates-page-team-bios-js": () => import("./../../../src/templates/PageTeamBios.js" /* webpackChunkName: "component---src-templates-page-team-bios-js" */),
  "component---src-templates-page-testimonials-js": () => import("./../../../src/templates/PageTestimonials.js" /* webpackChunkName: "component---src-templates-page-testimonials-js" */)
}

